// original idea from https://github.com/daniellaera/react-supabase-auth-provider.git
// https://blog.openreplay.com/authentication-in-react-with-supabase/
// https://supabase.com/docs/guides/auth/social-login/auth-google

import {Session, User} from '@supabase/supabase-js'
import {useContext, useState, useEffect, createContext} from 'react'
import {supabaseClient} from '../config/supabase'

// Define the context type
type AuthContextType = {
    loading: Boolean // Use lowercase `boolean`
    session: Session | null | undefined
    user: User | null | undefined
    event: string | null // Add event to the context type
    onboardingStep: string | null // onboarding step in cookie
    setOnboardingStep: (step: string | null) => void
    signOut: () => void
}

// Create the context with default values
const AuthContext = createContext<AuthContextType>({
    loading: true,
    session: null,
    user: null,
    event: null,
    onboardingStep: null,
    setOnboardingStep: () => {},
    signOut: () => {},
})

export const AuthProvider = ({children}: any) => {
    const [user, setUser] = useState<User | null>()
    const [session, setSession] = useState<Session | null>()
    const [loading, setLoading] = useState(true)
    const [event, setEvent] = useState<string | null>(null) // Track the event

    const [onboardingStep, setOnboardingStepState] = useState<string | null>(
        localStorage.getItem('onboarding_step'),
    )

    // Function to update onboarding_step
    const setOnboardingStep = (step: string | null) => {
        setOnboardingStepState(step)
        if (step) {
            localStorage.setItem('onboarding_step', step)
        } else {
            localStorage.removeItem('onboarding_step')
        }
    }

    // Function to handle sign-out
    // - multiple tabs on same browser
    // - multiple sessions for same user on multiple browsers
    const signOut = async () => {
        const localStoreKey = `sb-${process.env.REACT_APP_BASE_SUPABASE_PRJ}-auth-token`

        try {
            // Fetch the current session token
            const {
                data: {session},
            } = await supabaseClient.auth.getSession()

            // if there is no valid session then hard signout
            if (!session) {
                //session
                localStorage.removeItem(localStoreKey)
                //accesstoken if there one
                localStorage.removeItem('accessToken')
                // rest
                localStorage.removeItem('onboarding_step')
                setUser(null)
                console.log('Auth force signout remove  > ', localStoreKey)
                // window.location.reload() // Force reload of the app
            }

            // Sign out from Supabase
            const {error} = await supabaseClient.auth.signOut()

            if (error) {
                //session
                localStorage.removeItem(localStoreKey)
                //accesstoken if there one
                localStorage.removeItem('accessToken')
                // rest
                localStorage.removeItem('onboarding_step')
                setUser(null)
                console.log('Auth force signout remove  > ', localStoreKey)
                window.location.reload() // Force reload of the ap
            } else {
                // Clear local user state, cleanup with out window reload
                setUser(null)
                localStorage.removeItem(localStoreKey)
                localStorage.removeItem('accessToken')
                localStorage.removeItem('onboarding_step')
            }
        } catch (error) {
            console.error('Error during sign out:', error)

            localStorage.removeItem(localStoreKey)
            localStorage.removeItem('accessToken')
            localStorage.removeItem('onboarding_step')
            setUser(null)
            throw error
        }
    }

    useEffect(() => {
        const setData = async () => {
            try {
                console.log('Fetching session from Supabase...')
                const {
                    data: {session},
                    error,
                } = await supabaseClient.auth.getSession()
                if (error) throw error

                console.log('Session fetched:', session)
                setSession(session)
                setUser(session?.user)
                setLoading(false)
            } catch (err) {
                console.error('Error fetching session:', err)
            }
        }

        const {data: listener} = supabaseClient.auth.onAuthStateChange(
            (_event, session) => {
                //console.log('Auth state changed. Session:', session)
                console.log('Auth event triggered:', _event)
                setEvent(event) // Update the event in state
                setSession(session)
                setUser(session?.user)
                setLoading(false)

                if (_event === 'SIGNED_IN') {
                    const currentStep = localStorage.getItem('onboarding_step')
                    if (!currentStep) {
                        setOnboardingStep('0') // Initialize onboarding step
                    }
                }

                if (_event === 'SIGNED_OUT') {
                    console.log('User logged out. Synchronizing...')
                    window.location.reload()
                }
            },
        )

        setData()

        return () => {
            listener?.subscription.unsubscribe()
        }
    }, [])

    const value = {
        loading,
        session,
        user,
        event, // Provide the event in the context
        onboardingStep,
        setOnboardingStep,
        signOut,
        // signOut: () => supabaseClient.auth.signOut(),
    }

    // use a provider to pass down the value
    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    )
}

// Hook to use AuthContext
export const useAuth = () => {
    const context = useContext(AuthContext)
    if (context === undefined) {
        throw new Error('useAuth must be used within an AuthProvider')
    }
    return context
}
