import React, {useState} from 'react'
import {
    Switch,
    FormControlLabel,
    Typography,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
} from '@mui/material'

import {useThemeContext} from '../../hooks/ThemeMode'

const UserPreferences: React.FC = () => {
    const [pushNotifications, setPushNotifications] = useState<boolean>(true)
    const [language, setLanguage] = useState<string>('en')

    const {isDarkMode, toggleTheme} = useThemeContext()

    return (
        <div style={{padding: '16px'}}>
            <Typography variant="h5" gutterBottom>
                User Preferences
            </Typography>

            {/* Push Notifications Toggle */}
            <FormControlLabel
                control={
                    <Switch
                        checked={pushNotifications}
                        onChange={() =>
                            setPushNotifications(!pushNotifications)
                        }
                    />
                }
                label="Push Notifications"
            />

            {/* Dark/Light Theme Toggle */}
            <FormControlLabel
                control={<Switch checked={isDarkMode} onChange={toggleTheme} />}
                label="Dark Mode"
            />

            {/* Language Selection */}
            <FormControl fullWidth style={{marginTop: '16px'}}>
                <InputLabel>Language</InputLabel>
                <Select
                    value={language}
                    onChange={(e) => setLanguage(e.target.value as string)}
                >
                    <MenuItem value="en">English</MenuItem>
                    <MenuItem value="de">Deutsch</MenuItem>
                    {/* Add more languages as needed */}
                </Select>
            </FormControl>
        </div>
    )
}

export default UserPreferences
