// see https://supabase.com/docs/guides/auth/auth-helpers/auth-ui
// see  https://www.restack.io/docs/supabase-knowledge-supabase-auth-ui-guide

import {useEffect} from 'react'

import {useNavigate, useLocation} from 'react-router-dom'
import {Route} from '../../router'

import {Auth} from '@supabase/auth-ui-react'
import {ThemeSupa} from '@supabase/auth-ui-shared'
//for future decoration
//import {getLoginCustomTheme} from './LoginCustomTheme'

import {supabaseClient} from '../../config/supabase'
import {useAuth} from '../../hooks/Auth'

import {useBottomNav} from '../../hooks/BottomNavControl'

const Login = () => {
    const {loading, session, user, event} = useAuth()

    // Routes where Navbar and BottomNav should NOT be shown
    const navigate = useNavigate()
    const location = useLocation()
    const {hideBottomNav} = useBottomNav()

    useEffect(() => {
        if (!loading) {
            if (!session && location.pathname !== Route.LOGIN) {
                // navigate unauthenticated users to the public Root route
                navigate(Route.ROOT, {replace: true})
                console.log('Login -> Root')
            } else if (session && user) {
                // navigate authenticated users to the Home route
                navigate(Route.HOME, {replace: true})
                console.log('Login (session) -> Home')
            }
        }
    }, [loading, session, navigate, location.pathname])

    useEffect(() => {
        // navigate signed_in user to Home route
        if (event === 'SIGNED_IN') {
            navigate(Route.HOME, {replace: true})
            console.log('Login (SIGNED_IN) -> Home')
        }
    }, [event, navigate])

    useEffect(() => {
        hideBottomNav()
    }, [hideBottomNav])

    return (
        <div className="PageContainer">
            <Auth
                supabaseClient={supabaseClient}
                providers={['facebook', 'google']} // Facebook-Provider hinzufügen
                appearance={{theme: ThemeSupa}}
                //theme="dark"
                view="sign_in"
                // only for password-less social logins
                redirectTo={process.env.REACT_APP_BASE_START_URL}
                // see https://supabase.com/docs/guides/auth/redirect-urls
                // When using passwordless sign-ins or third-party providers, the Supabase client library
                // methods provide a redirectTo parameter to specify where to redirect the user to after authentication.
                // By default, the user will be redirected to the SITE_URL but you can modify the SITE_URL or add additional redirect
                // URLs to the allow list. Once you've added necessary URLs to the allow list, you can specify the URL you
                // want the user to be redirected to in the redirectTo parameter.
            />
        </div>
    )
}

export default Login
