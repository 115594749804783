import {Typography} from '@mui/material'

const Chat = () => {
    return (
        <div className="PageContainer">
            <Typography variant="h1">Chat</Typography>
            <Typography variant="body1">cooming soon</Typography>
            <Typography variant="body2" sx={{color: 'text.secondary'}}>
                needs time
            </Typography>
        </div>
    )
}

export default Chat
