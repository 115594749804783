import React, {useState} from 'react'
import {Outlet, useLocation} from 'react-router-dom'
import {CssBaseline} from '@mui/material'

import Navbar from './components/organisms/Navbar/Navbar'
import BottomNav from './components/organisms/BottomNav/BottomNav'

import {BottomNavProvider} from './hooks/BottomNavControl'
import {useAuth} from './hooks/Auth'
import {ThemeProviderWrapper} from './hooks/ThemeMode'

//app main layout
import './App.css'

function App() {
    // authuser
    const {loading, session} = useAuth()

    // Routes where Navbar and BottomNav should NOT be shown
    const location = useLocation()
    const excludedRoutes = ['/'] // Add splash screen route or other public routes here
    const isProtectedRoute = !excludedRoutes.includes(location.pathname)

    // hide bottomNav dynamic
    const [bottomNavHeight, setBottomNavHeight] = useState('80px')

    return (
        <div
            className="App"
            style={
                {
                    '--bottom-nav-height': bottomNavHeight,
                } as React.CSSProperties
            }
        >
            <ThemeProviderWrapper>
                <CssBaseline />
                <BottomNavProvider>
                    <div className="AppContainer">
                        {session && isProtectedRoute && <Navbar />}
                        <Outlet />
                        {session && isProtectedRoute && (
                            <BottomNav
                                setBottomNavHeight={setBottomNavHeight}
                            />
                        )}
                    </div>
                </BottomNavProvider>
            </ThemeProviderWrapper>
        </div>
    )
}

export default App
