//import {useTheme} from '@mui/material/styles'

import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import {styled} from '@mui/material/styles'

import GetLocation from './GeoLocation'
import UpdateApp from './UpdateApp'
import FontEnvGitInfo from './FontEnvGitInfo'
import StandaloneCheck from './StandaloneCheck'

import UserPreferences from '../../components/molecules/UserPrefences'

const Profile = () => {
    //const theme = useTheme()

    const Item = styled(Paper)(({theme}) => ({
        backgroundColor: '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        ...theme.applyStyles('dark', {
            backgroundColor: '#1A2027',
        }),
    }))

    return (
        <div className="PageContainer">
            <Stack spacing={1}>
                <Item>
                    <UserPreferences />
                </Item>
                <Item>
                    <GetLocation></GetLocation>
                </Item>
                <Item>
                    <UpdateApp></UpdateApp>
                </Item>
                <Item>
                    <StandaloneCheck />
                </Item>
                <Item>
                    <FontEnvGitInfo></FontEnvGitInfo>
                </Item>
            </Stack>
        </div>
    )
}

export default Profile
