import React, {useState} from 'react'
import {
    TextField,
    FormControl,
    Select,
    MenuItem,
    Checkbox,
    FormControlLabel,
    Button,
    Box,
    Typography,
    Autocomplete,
} from '@mui/material'
import Cropper from 'react-easy-crop'
import {OnboardingContainer, FormElementWrapper} from './StyledComponents'
import OnboardingData from '../../types/OnboardingData'

interface Step1Props {
    userImageUrl: string | null
    onSubmit: (data: OnboardingData) => void
}

const Step1: React.FC<Step1Props> = ({userImageUrl, onSubmit}) => {
    const [language, setLanguage] = useState('')
    const [checked, setChecked] = useState(false)
    const [username, setUsername] = useState('')
    const [profilePicture, setProfilePicture] = useState<string | null>(
        userImageUrl,
    )
    const [uploadedImage, setUploadedImage] = useState<string | null>(null)
    const [croppedImage, setCroppedImage] = useState<string | null>(null)
    const [crop, setCrop] = useState({x: 0, y: 0})
    const [zoom, setZoom] = useState(1)
    const [isCropping, setIsCropping] = useState(false)

    const cities = ['Berlin', 'Munich', 'Hamburg', 'Cologne', 'Frankfurt']

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0]
        if (file) {
            const reader = new FileReader()
            reader.onload = () => {
                setUploadedImage(reader.result as string)
                setIsCropping(true)
            }
            reader.readAsDataURL(file)
        }
    }

    const handleCropComplete = (croppedArea: any, croppedAreaPixels: any) => {
        // For demonstration, we will just set the uploaded image as croppedImage
        setCroppedImage(uploadedImage) // Replace with proper cropping logic
        setIsCropping(false)
    }

    const handleSubmit = () => {
        const data: OnboardingData = {
            username,
            language,
            over18: checked,
            profilePicture: croppedImage || profilePicture,
            city: '',
        }
        onSubmit(data)
    }

    return (
        <OnboardingContainer>
            {/* Profile Picture Upload */}
            <FormElementWrapper>
                <Typography variant="h6">Upload Profile Picture</Typography>
                <Box
                    sx={{
                        width: '150px',
                        height: '150px',
                        borderRadius: '50%',
                        overflow: 'hidden',
                        margin: 'auto',
                        border: '2px solid #ccc',
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: '#f9f9f9',
                    }}
                    onClick={() =>
                        document.getElementById('file-input')?.click()
                    }
                >
                    {croppedImage || profilePicture ? (
                        <img
                            src={croppedImage || profilePicture || undefined}
                            alt="Profile"
                            style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                            }}
                        />
                    ) : (
                        <Typography
                            variant="body2"
                            sx={{textAlign: 'center', color: '#888'}}
                        >
                            Click to Upload
                        </Typography>
                    )}
                </Box>
                <input
                    id="file-input"
                    type="file"
                    accept="image/*"
                    style={{display: 'none'}}
                    onChange={handleFileChange}
                />
                {isCropping && (
                    <Box
                        sx={{
                            position: 'relative',
                            width: '100%',
                            height: 300,
                            background: '#333',
                            marginTop: '16px',
                        }}
                    >
                        <Cropper
                            image={uploadedImage || ''}
                            crop={crop}
                            zoom={zoom}
                            aspect={1}
                            onCropChange={setCrop}
                            onZoomChange={setZoom}
                            onCropComplete={handleCropComplete}
                        />
                    </Box>
                )}
            </FormElementWrapper>

            {/* Username Input */}
            <FormElementWrapper>
                <TextField
                    label="Username"
                    variant="outlined"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                />
            </FormElementWrapper>

            {/* Language Selection */}
            <FormElementWrapper>
                <FormControl>
                    <Select
                        value={language}
                        onChange={(e) => setLanguage(e.target.value)}
                        displayEmpty
                        variant="outlined"
                    >
                        <MenuItem value="">
                            <em>Choose Language</em>
                        </MenuItem>
                        <MenuItem value="en">English</MenuItem>
                        <MenuItem value="de">Deutsch</MenuItem>
                        <MenuItem value="fr">Français</MenuItem>
                    </Select>
                </FormControl>
            </FormElementWrapper>

            {/* City Selection */}
            <FormElementWrapper>
                <Autocomplete
                    options={cities}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="City"
                            variant="outlined"
                        />
                    )}
                    onChange={(e, value) => console.log(value)}
                />
            </FormElementWrapper>

            {/* Age Confirmation */}
            <FormElementWrapper>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={checked}
                            onChange={(e) => setChecked(e.target.checked)}
                        />
                    }
                    label="I am over 18 years old"
                />
            </FormElementWrapper>

            {/* Submit Button */}
            <FormElementWrapper>
                <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={handleSubmit}
                >
                    Submit
                </Button>
            </FormElementWrapper>
        </OnboardingContainer>
    )
}

export default Step1
