import {useEffect} from 'react'

// see https://supabase.com/docs/guides/auth/auth-helpers/auth-ui
// see  https://www.restack.io/docs/supabase-knowledge-supabase-auth-ui-guide

import {Auth} from '@supabase/auth-ui-react'
import {ThemeSupa} from '@supabase/auth-ui-shared'
// for future decoration
// import {getLoginCustomTheme} from './LoginCustomTheme'

import {supabaseClient} from '../../config/supabase'
import {useBottomNav} from '../../hooks/BottomNavControl'

const SignUp = () => {
    const {hideBottomNav} = useBottomNav()

    useEffect(() => {
        hideBottomNav()
    }, [hideBottomNav])

    return (
        <div className="PageContainer">
            <Auth
                supabaseClient={supabaseClient}
                providers={['facebook', 'google']} // Facebook-Provider hinzufügen
                appearance={{theme: ThemeSupa}}
                //theme="dark"
                view="sign_up"
            />
        </div>
    )
}

export default SignUp
