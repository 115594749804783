import React from 'react'
import {Box, Typography} from '@mui/material'
import {useTheme} from '@mui/material/styles'

const FontEnvGitInfo: React.FC = () => {
    const theme = useTheme()

    return (
        <Box
            sx={{
                width: '100%',
                maxWidth: 600,
                margin: 'auto',
                padding: 2,
                borderRadius: 2,
                boxShadow: 3,
                backgroundColor: '#f4f4f9',
            }}
        >
            {/* Box for font family */}
            <Box
                sx={{
                    padding: 2,
                    border: '1px solid #ddd',
                    borderRadius: 2,
                    backgroundColor: '#f5f5f5',
                    marginBottom: 2,
                }}
            >
                <Typography variant="body1">
                    <strong>Font Family:</strong>{' '}
                    {theme.typography.fontFamily || 'Not Available'}
                </Typography>
            </Box>

            {/* Box for environment and git tag */}
            <Box
                sx={{
                    padding: 2,
                    border: '1px solid #ddd',
                    borderRadius: 2,
                    backgroundColor: '#f5f5f5',
                }}
            >
                <Typography variant="body1">
                    <strong>Env:</strong>{' '}
                    {process.env.REACT_APP_ENV || 'Not Defined'}
                </Typography>
                <Typography variant="body1">
                    <strong>Git Tag:</strong>{' '}
                    {process.env.REACT_APP_VERSION || 'Not Defined'}
                </Typography>
            </Box>
        </Box>
    )
}

export default FontEnvGitInfo
